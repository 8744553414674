.filter {
    padding: 10px;
    padding-left: 10px;
    margin-top: 20PX;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: black;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 5px;
    width: 100%;
    
}
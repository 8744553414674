/* Header Panel */
.header-panel {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.filter-item {
  display: block;
  margin: 5px 0;
}

.logo {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.search-bar {
  flex: 1;
  margin: 0 20px;
}

.whatsapp-number {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #faf5f5;
  white-space: nowrap;
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* Menu Bar */
.menu-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #3c3c3c;
  padding: 5px;
  position: fixed;
  width: 100%;
  top: 95px; /* Adjust based on header panel height */
  left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-right: auto;
}

.menu-button {
  background-color: #3c3c3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
}

.sidebar-container {
  position: relative;
}

/* WhatsApp Icon */
.whatsapp-icon {
  position: fixed;
  top: 100px;
  right: 0;
  color: #25D366;
  font-size: 18px;
  cursor: pointer;
  z-index: 1000;
}

/* Sidebar Menu */
.sidebar-menu {
  position: fixed;
  top: 155px;
  left: 0;
  width: 180px;
  height: calc(100% - 120px);
  background-color: #3c3c3c;
  color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 999;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between; /* Add this line */
}

.filter-panel {
  background-color: #555;
  color: white;
  padding: 10px;
  border-radius: 1px;
  margin: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  text-align: left;
  min-width: 120px;
  flex-direction: column;
}

/* Main Content Area */
.main-content {
  margin-top: 230px; /* Adjust based on header and menu bar height */
  padding: 20px;
}


/* Media query for smaller screens */
@media (max-width: 768px) {
  .menu-bar {
    padding: 10px; /* Increase padding for better touch targets on smaller screens */
    top: 80px; /* Adjust top spacing if needed */
  }
}

@media (max-width: 576px) {
  .menu-bar {
    flex-direction: column; /* Stack items vertically on very small screens */
    padding: 5px; /* Increase padding for more space */
    top: 90px; /* Adjust top spacing if needed */
    align-items: flex-start; /* Align items to the start */
  }
}

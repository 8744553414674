/* ChatContainer.css */

.chat-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #888;
  font-size: 18px;
  cursor: pointer;
  z-index: 100;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #000;
}
.chat-box {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.user-message {
  align-self: flex-end;
  background-color: #daf8e3;
}
.bot-message {
  align-self: flex-start;
  background-color: #f1f0f0;
}
.message-content {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: flex-start;
  width: 100%; 
}

.book-image {
  max-width: 60px;
  height: auto;
  margin-right: 10px; /* Add spacing between images */
  border: 1px solid #ccc;
  border-radius: 5px;
  object-fit: contain;
}

.message-images {
  display: flex;
  flex-direction: row; /* Display images side by side */
  margin-top: 10px; /* Add spacing above images */
  flex-wrap: wrap;
}

.message-text {
  word-wrap: break-word;
  white-space: pre-wrap;
  flex: 1;
}
.bot-message {
  align-self: flex-start;
  background-color: #f1f0f0;
}

.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

.input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.loading span {
  font-size: 18px;
  animation: blink 1s infinite;
}
.book-info {
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.book-details {
  margin-bottom: 10px;
}

.book-details p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.5;
}

.show-button button {
  border: none;
  background: none; 
  color: #007bff;
  font-size: 15px;
  cursor: pointer; 
  padding: 0; 
}

.show-button button:hover {
  text-decoration: underline; 
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Media Query for Phone Screens */
@media (max-width: 780px) {
    .chat-container {
      height: 350px;
      width: 350px;
    }
    .close-button {
      top: 5px;
      right: 5px;
      font-size: 16px;
    }
    .input-container {
      flex-direction: row;
    }
  
    .input {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .button {
      padding: 2px 8px; 
      margin: 1px 0 0 2px;
      border-radius: 5px;
      margin-bottom: 10px;
      width: 20%;
    }
  }
  

/* Staffpicks.css */

.staffpicks-container {
    padding: 0 150px;
  }
  
  .staffpicks-header {
    margin-top: 100px;
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  
  .staffpicks-header span {
    flex-grow: 1;
  }
  
  .staffpicks-link {
    font-size: 1rem;
    color: black;
    text-decoration: none;
  }
  
  .staffpicks-books {
    margin-top: 20px;
  }
  
  /* Media query for small phone screens */
  @media (max-width: 576px) {
    .staffpicks-container {
      padding: 0 8px; 
    }
  
    .staffpicks-header {
      flex-direction: row; 
      font-size: 1.5rem;
    }
  
    .staffpicks-link {
      font-size: 0.9rem; 
      /* margin-top: 10px;  */
    }
  }
  
.price-container {
    text-align: center;
    color: #555;
  }
  
  .item-added-msg {
    font-size: 1rem;
    margin: 0;
  }
  
  .price-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0;
  }
  
  .price-text {
    font-size: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .cart-btn {
    border: none;
    background-color: white;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .price-text {
      font-size: 0.9rem;
    }
    .cart-btn {
      margin-left: 5px;
    }
  }
  
  @media (max-width: 576px) {
    .price-text {
      font-size: 0.8rem;
    }
    .cart-btn {
      margin-left: 3px;
    }
  }
  


.navbar-custom {
    background-color: #3c3c3c;
    z-index: 1000;
    position: sticky;
  }
  
  .navbar-container {
    background-color: #3c3c3c;
    padding: 0;
  }
  
  .navbar-toggle {
    background-color:white;
    margin-bottom: 8px;
  }
  
  .navbar-collapse-custom {
    background-color: #3c3c3c;
  }
  
  .nav-item-spacing {
    margin-right: 150px;
  }
  
  
  
  .nav-link-custom {
    color: #fff;
    text-decoration: none;
  }
  
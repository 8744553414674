.grid-container {
  padding-top: 20px;
}

.book-grid {
  display: grid;
  gap: 10px;
  justify-content: center;
}

/* Media Queries */
@media (max-width: 1600px) {
  .book-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1450px) {
  .book-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 960px) {
  .book-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px; 
  }
}

@media (max-width: 576px) {
  .book-grid {
    grid-template-columns: repeat(1, 1fr); 
    gap: 50px; 
  }

  .grid-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer {
  background-color: #f0f0f0;
  padding: 5px 5px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer-column {
  width: 100%;
  padding: 5px;
  text-align: center;
}

.footer-column.logo-column {
  text-align: left; /* Align logo to the left on larger screens */
}

.footer-logo {
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer-column.links-column {
  display: flex;
  align-items: center; /* Vertically align content */
  justify-content: center; /* Align to the left */
}

.footer-column.links-column ul {
  margin-left: 10px; /* Adjust spacing as needed */
}

.footer-list a {
  text-decoration: none;
  color: #337ab7;
}

.footer-list a:hover {
  text-decoration: underline;
}

.text-muted {
  font-size: 14px;
  color: #6c757d;
}

.list-inline {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.list-inline-item {
  margin: 0 5px;
}

.custom-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 0;
}

.copy-right {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
 

  .footer-column {
    text-align: center;
  }

  .footer-list {
    gap: 5px;
  }

  .text-muted {
    font-size: 12px;
  }

  .footer-column.links-column {
    justify-content: center; /* Center align for smaller screens */
  }
  .copy-right {
    font-size: 12px;
  }
} 

@media (max-width: 576px) {
  .footer-logo {
    width: 50px;
  }

  .footer-container {
    flex-direction: column;
  }

  .footer-list {
    flex-direction: row;
    gap: 5px;
  }

  .text-muted {
    font-size: 12px;
  }
  .copy-right {
    font-size: 12px;
  }
}

/*----- 20. Cart page  -----*/

.cart-main-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 50px;
    }
  }
}

h3.cart-page-title {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 10px;
}

.cart-table-content {
  table {
    border: 1px solid #ebebeb;
    thead > tr {
      border: 1px solid #ebebeb;
      background-color: #f9f9f9;
      th {
        font-size: 12px;
        font-weight: 500;
        padding: 15px 30px 15px;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        text-transform: uppercase;
        color: #333;
        border-top: none;
        @media #{$lg-layout} {
          padding: 15px 25px 15px;
        }
        @media #{$xs-layout} {
          padding: 15px 15px 15px;
        }
      }
    }

    tbody > tr {
      border-bottom: 1px solid #ebebeb;
      td.product-thumbnail {
        width: 120px;
      }
      td.product-name {
        width: 350px;
        text-align: left;
        a {
          font-size: 14px;
          font-weight: 500;
          color: #333;
          &:hover {
            color: $theme-color;
          }
        }
      }

      td.product-price-cart {
        width: 350px;
        span {
          font-weight: 500;
          color: #333;
          &.old {
            margin-right: 5px;
            text-decoration: line-through;
            color: #8e8e8e;
          }
        }
      }

      td.product-subtotal {
        font-weight: 500;
        color: #333;
      }

      td.product-quantity {
        width: 300px;
        .cart-plus-minus {
          position: relative;
          display: inline-block;
          width: 90px;
          height: 35px;
          padding: 0;
          .qtybutton {
            font-size: 14px;
            position: absolute;
            float: inherit;
            width: 20px;
            margin: 0;
            cursor: pointer;
            transition: all 0.3s ease;
            text-align: center;
            color: #333;
            border: none;
            background: none;
          }
          .dec.qtybutton {
            top: 0;
            left: 0;
            height: 35px;
            border-right: 1px solid #e5e5e5;
          }
          .inc.qtybutton {
            top: 0;
            right: 0;
            height: 35px;
            border-left: 1px solid #e5e5e5;
          }
          input.cart-plus-minus-box {
            font-size: 12px;
            float: left;
            width: 90px;
            height: 35px;
            margin: 0;
            padding: 0;
            text-align: center;
            color: #333;
            border: 1px solid #e1e1e1;
            background: transparent;
          }
        }
      }

      td.product-remove {
        width: 80px;
        a,
        button {
          font-size: 15px;
          margin: 0 10px;
          color: #666;
          border: none;
          background: none;
          &:hover {
            color: $theme-color;
          }
        }
      }

      td.product-wishlist-cart > a,
      td.product-wishlist-cart > button {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        display: block;
        margin: 0 auto;
        padding: 8px 10px;
        text-transform: uppercase;
        color: #fff;
        border: none;
        border-radius: 50px;
        background-color: $theme-color;
        &:hover,
        &.active {
          background-color: #333;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }

      td {
        font-size: 13px;
        padding: 20px 0 20px 20px;
        text-align: center;
        color: #333;
      }
    }
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 40px;
  @media #{$xs-layout} {
    display: block;
    padding: 20px 0 10px;
  }
  .cart-shiping-update > a,
  .cart-clear > button,
  .cart-clear > a {
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    padding: 15px 50px 15px;
    text-transform: uppercase;
    color: #363f4d;
    border-radius: 50px;
    background-color: #f2f2f2;
    @media #{$md-layout} {
      font-size: 12px;
      padding: 15px 20px;
    }
    @media #{$xs-layout} {
      margin: 0 0 10px;
      padding: 15px 30px;
    }
    &:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }

  .cart-clear > button {
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    @media #{$md-layout} {
      margin-right: 10px;
    }
  }
}

.cart-tax,
.discount-code-wrapper {
  padding: 30px 20px 40px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f9f9f9;
  @media #{$lg-layout} {
    padding: 30px 15px 40px;
  }
  @media #{$xs-layout} {
    padding: 30px 15px 40px;
  }

  .title-wrap {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #e3e1e1;
    }

    h4.cart-bottom-title {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      padding-right: 10px;
    }

    .section-bg-gray {
      background-color: #f8f9f9;
    }
  }
}

.grand-totall {
  padding: 30px 20px 40px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f9f9f9;
  @media #{$lg-layout} {
    padding: 30px 15px 40px;
  }

  h4.cart-bottom-title {
    font-size: 16px;
    font-weight: 500;
    padding-right: 10px;
  }

  h5 {
    font-size: 12px;
    margin: 25px 0 20px;
    span {
      font-size: 16px;
      font-weight: 500;
      float: right;
    }
  }

  a {
    font-size: 12px;
    font-weight: 500;
    padding: 15px 10px;
    color: #fff;
    border-radius: 50px;
    background-color: $theme-color;
    &:hover {
      background-color: #333;
    }
  }
}

.custom-card {
  width: 100% !important;
  max-width: 14rem !important;
  margin: 10px !important;
  border: none !important;
  transition: 0.3s !important;
  position: relative !important;
}

@media (max-width: 1024px) {
  .custom-card {
    max-width: 10rem !important;
  }
}

@media (max-width: 768px) {
  .custom-card {
    max-width: 6rem !important;
  }
}

@media (max-width: 576px) {
  .custom-card {
    max-width: 4rem !important;
  }
}

.custom-card-body {
  padding: 5px !important;
  text-align: center !important;
}

.custom-card-title {
  font-size: 0.9rem !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

@media (max-width: 768px) {
  .custom-card-title {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 576px) {
  .custom-card-title {
    font-size: 0.7rem !important;
  }
}

.custom-image-container {
  width: 150px !important;
  height: 200px !important;
  margin: 0 auto !important;
  overflow: hidden !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
}

@media (max-width: 768px) {
  .custom-image-container {
    width: 100px !important;
    height: 150px !important;
  }
}

@media (max-width: 576px) {
  .custom-image-container {
    width: 80px !important;
    height: 120px !important;
  }
}

.custom-image {
  width: 150px !important;
  height: 200px !important;
  object-fit: cover !important;
}

@media (max-width: 768px) {
  .custom-image {
    width: 100px !important;
    height: 150px !important;
  }
}

@media (max-width: 576px) {
  .custom-image {
    width: 80px !important;
    height: 120px !important;
  }
}

.custom-error-text {
  font-size: 1rem !important;
  text-align: center !important;
  padding: 10px !important;
  color: #333 !important;
}

@media (max-width: 768px) {
  .custom-error-text {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 576px) {
  .custom-error-text {
    font-size: 0.8rem !important;
  }
}

.custom-badge {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  background-color: #0a4ee0 !important;
  font-size: 0.7rem !important;
  padding: 5px !important;
  z-index: 1 !important;
}

@media (max-width: 768px) {
  .custom-badge {
    font-size: 0.6rem !important;
    padding: 4px !important;
  }
}

@media (max-width: 576px) {
  .custom-badge {
    font-size: 0.5rem !important;
    padding: 3px !important;
  }
}


.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
    color: #333;
    background-color: #f8f9fa;
  
    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: #dc3545; /* Red color indicating error */
    }
  
    p {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
  
    a {
      padding: 0.75rem 1.5rem;
      background-color: #007bff; /* Blue color for the button */
      color: #fff;
      text-decoration: none;
      border-radius: 0.25rem;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
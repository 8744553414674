.book-info-container {
    text-align: center;
    margin-bottom: 8px;
    color: #333;
  }
  
  .book-author {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 768px) {
    .book-author {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .book-author {
      font-size: 0.8rem;
    }
  }
  

.custom-table td, 
.custom-table th {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  vertical-align: middle;
}

.custom-table {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 3rem !important;
}


.footer {
  margin-top: 100px; 
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; 
}

.payment-container {
  margin-top: 300px;
  min-height: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white; 
}

.payment-header {
  text-align: center;
  margin-bottom: 20px;
}


@media (max-width: 576px) {
  .custom-table th,
  .custom-table td {
    font-size: 14px;
    padding: 10px;
  }

  .custom-table th {
    text-align: center;
  }

  .custom-table td {
    text-align: center; 
  }
}


.custom-table {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.genre-components-container {
  padding: 0 150px;
}

.genre-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.genre-nav .genre-nav-items {
  display: flex;
  flex: 1;
  justify-content: center;
}

.genre-nav .nav-item {
  text-align: center;
  padding: 2px;
}

.genre-nav .nav-link {
  font-size: 1rem;
}

.view-more-container {
  flex-shrink: 0;
}

.view-more {
  text-decoration: none;
  color: black;
}
.genre-books-row {
  padding-top: 20px;
}


/* Media query for small phone screens */
@media (max-width: 576px) {
  .genre-components-container {
    padding: 0 5px; 
  }
  
  .genre-nav {
    display: block; 
  }
  
  .genre-books-row {
    display: flex;
    flex-direction: column; 
  }
  
  .genre-nav .nav-link {
    font-size: 0.6rem; 
  }
  .view-more{
    padding-left:300px;
  }
  .genre-nav .view-more {
    font-size: 0.8rem; 
}
.genre-nav .nav-item {
  margin: 0 3px; 
}
.genre-nav .nav-link {
  padding: 5px 10px; 
  font-size: 0.6rem; 
}
}
#nprogress .spinner {
    position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
  }
  
  #nprogress .spinner-icon {
    width: 50px; 
    height: 50px; 
    box-sizing: border-box;
    border: solid 5px transparent; 
    border-top-color: #29d; 
    border-left-color: #29d;
    border-radius: 50%;
    animation: nprogress-spinner 400ms linear infinite;
  }
  
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
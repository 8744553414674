
.sideBar {
    position: fixed;
    top: 158px;
    left: 0;
    width: 220px;
    padding: 20px;
    height: 100vh;
    overflow-y: auto;
    font-size: large;
  }
  
  @media (max-width: 786px) {
    .sideBar {
      width: 220px;
      z-index: auto;
      height: 100%;
    }
    .nav-item-spacing {
        padding-top: 10px;
        padding-bottom: 10px;
    }
  }
  
  .nav-item-first {
    margin-left: 100px;
  }
  .nav-item-spacing {
    padding-top: 10px;
    margin-right: 150px;
  }
  

  .navbar-custom {
    background-color: #3c3c3c;
  }
  
  .navbar-collapse-custom {
    background-color: #3c3c3c;
    margin-bottom: 8px;
  }
  

  .filter-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  